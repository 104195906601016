var sliding = false;

$(document).ready(function () {
    var navDropdown = $('.u_nav__dropdown');
    var toggleButton = $('.toggle-nav');

    $('.toggle-nav').click(function () {
        toggleNavDropdown(navDropdown, toggleButton);
    });

    $('#account-trigger').keyup(function (e) {
        e.preventDefault();
        if (e.which === 13) {
            $('#account-trigger').click();
        }
    });
});

function toggleNavDropdown(dropdown, button) {
    if (sliding) return; // Prevents schenanigans

    sliding = true;

    var openClass = "open";
    var hideClass = "hide";

    if (dropdown.hasClass(openClass)) {
        dropdown.removeClass(openClass);
        button.removeClass(openClass);
        button.attr("aria-label", window.OverDrive.SiteCopy.get("ariaLabels.openNavigation"));

        setTimeout(function () {
            dropdown.addClass(hideClass);
            sliding = false;
        }, 500);
    } else {
        dropdown.removeClass(hideClass);
        setTimeout(function () {
            dropdown.addClass(openClass);
            button.addClass(openClass);
            button.attr("aria-label", window.OverDrive.SiteCopy.get("ariaLabels.closeNavigation"));
        }, 1);
        setTimeout(function () {
            sliding = false;
        }, 500);
    }
}
